// @flow
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import * as React from 'react'
import CoimexDrawer from './CoimexDrawer'
import { useRouter } from 'next/router'
import { Button } from '@material-ui/core';
import Link from 'next/link';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useTranslation } from 'next-i18next'
export const MenuDrawer = React.forwardRef((props, ref) => {
    const bottomSheet = React.useRef(null)
    const Language = React.useRef(null)
    const router = useRouter()
    const { t, i18n } = useTranslation()
    async function handleLang(key) {
        i18n.changeLanguage(key);
        router.push(router.pathname, undefined, { locale: key })
        localStorage.setItem('lang', key);
    }
    React.useImperativeHandle(ref, () => ({
        openSheet() {
            bottomSheet.current.toggleDrawer()
        },
    }))

    const [expanded, setExpanded] = useState(false);

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };
    function closeSheet() {
        bottomSheet.current.closeDrawer()
    }
    return (

        <CoimexDrawer
            Paper={{ height: '100%', backgroundColor: '#FDFDFD', width: '100%' }}
            //@ts-ignore
            ref={bottomSheet}
            position={"top"}
        >

            <div className="w-[90%] m-auto mt-6">
                <div className={"flex justify-between items-center"}>
                    <Link href={"/"}><p className={"text-base text-black font-MontserratMedium"} onClick={() => bottomSheet.current.closeDrawer()}>Coimex</p></Link>

                    {/* <Link href={"/"}> <p className={"text-base text-black font-MontserratMedium"} >Coimex</p></Link> */}
                    <CloseSvg onClick={() => bottomSheet.current.closeDrawer()} />
                </div>
            </div>
            <Divider className="w-full bg-#C9C5CA h-[1px] mt-4" />
            <div className="w-12/12 m-auto mt-6">
                <Panle closeSheet={closeSheet} data={props.linksPlatform} handleChange={handleChange} id={1} expanded={expanded} title={t("sidemenu.1")} />
                <Panle closeSheet={closeSheet} data={props.links} handleChange={handleChange} id={2} expanded={expanded} title={t("sidemenu.2")} />
                <Panle closeSheet={closeSheet} data={props.blockChain} handleChange={handleChange} id={3} expanded={expanded} title={t("Header.12")} />

                <Link href={"/industries"}><ul className="mt-3 cursor-pointer hover:bg-PrimaryA py-2 pl-7 rounded-xl text-fontColorc font-MontserratRegular" onClick={() => bottomSheet.current.closeDrawer()}>{t("sidemenu.3")}</ul></Link>
                <Link href={"/trust"}><ul className="mt-3 cursor-pointer hover:bg-PrimaryA py-2 pl-7 rounded-xl text-fontColorc font-MontserratRegular" onClick={() => bottomSheet.current.closeDrawer()}>{t("sidemenu.4")}</ul></Link>
                <Link href={"https://coimex.co/blog/"}><ul className="mt-3 cursor-pointer rounded-xl hover:bg-PrimaryA py-2 pl-7 text-fontColorc font-MontserratRegular" onClick={() => bottomSheet.current.closeDrawer()}>{t("sidemenu.5")}</ul></Link>
                <Link href={"/about-us"}><ul className="mt-3 cursor-pointer hover:bg-PrimaryA py-2 pl-7 rounded-xl text-fontColorc font-MontserratRegular" onClick={() => bottomSheet.current.closeDrawer()}>{t("sidemenu.6")}</ul></Link>
                <div className="w-11/12 flex m-auto justify-center flex-col items-center">
                    <Link href={"https://app.coimex.co/#/auth/register"}>
                        <div className="bg-fontColorB  w-full h-[42px]  rounded-full flex justify-center flex-col items-center text-white font-MontserratMedium text-base mt-10"><p>{t("sidemenu.0")}</p>
                        </div>
                    </Link>
                    
                
                    <Divider className="w-full bg-#C9C5CA h-[1px] mt-8" />

                    <div className="flex items-center py-4">
                        <p onClick={() => handleLang("en")} className={`${i18n.language == "en" ? "font-MontserratSemiBold" : "font-MontserratRegular"}  text-[#313033]  flex leading-6  text-lg select-none cursor-pointer`}>English</p>
                        <Divider orientation="vertical" variant="middle" className="w-[2px] m-3 h-6 bg-textColor" />
                        <p onClick={() => handleLang("tr")} className={`${i18n.language == "tr" ? "font-MontserratSemiBold" : "font-MontserratRegular"}  text-[#313033]  flex leading-6  text-lg select-none cursor-pointer`}>Turkish</p>
                    </div>
                </div>
            </div>

        </CoimexDrawer>

    )
}
)

// i18n.changeLanguage("fa")


{/* <div className=" w-full  h-full rounded-full lowercase  font-MontserratMedium text-base">Sign in
                </div> */}

const CloseSvg = (props) => <svg onClick={props.onClick} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z" fill="#252525" />
</svg>



const Panle = ({ closeSheet, data, handleChange, id, expanded, title }) => {
    return (
        <Accordion elevation={0} style={{ padding: 0, marginTop: 0, outline: 0, backgroundColor: "#FDFDFD", position: "inherit", border: "none" }} expanded={expanded === id} onChange={handleChange(id)}>
            <AccordionSummary
                style={{ border: "none", width: '98%', outline: "none" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={title+Math.random()}
                id={title+Math.random()}
            >
                <Typography className=" cursor-pointer  hover:bg-PrimaryA py-2 pl-3  rounded-xl text-fontColorc font-MontserratRegular" sx={{ width: '100%' }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails >
                {data.map((link) => (
                    <div key={link.href}>

                        <Link

                            href={link.href}
                        >
                            <Typography onClick={closeSheet} className="text-black ml-4 cursor-pointer  hover:bg-PrimaryA py-2 rounded-xl text-base font-MontserratRegular px-[12px]" sx={{ width: '100%' }}>
                                {link.label}
                            </Typography>
                        </Link>

                    </div>

                ))}
            </AccordionDetails>

        </Accordion>
    )
}