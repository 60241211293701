import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

// type Anchor = 'top' | 'left' | 'bottom' | 'right'
// type Props = {
//   position: string
//   ref: any
//   Paper: any
// }
// type Ref = {
//   focus: () => void
// } | null
const CoimexDrawer = React.forwardRef((props, ref) => {
  const { position } = props
  // toggleDrawer(anchor, true)
  // const [selectedValue, setSelectedValue] = React.useState('a')

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  React.useImperativeHandle(ref,() => ({
    toggleDrawer() {
      setState({
        [position]: true,
      })
    },
    closeDrawer() {
      setState({
        [position]: false,
      })
    },
  }))

  const toggleDrawer = (anchor, open) =>(event) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedValue(event.target.value)
  //   props.onClick(event.target.value)
  // }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={()=>toggleDrawer(anchor, false)}
      onKeyDown={()=>toggleDrawer(anchor, false)}
    >
      {props.children}
    </Box>
  )

  return (
    <div>
      {([position]).map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={}>{anchor}</Button> */}
          <SwipeableDrawer
           variant='persistent'
            PaperProps={{ style: props.Paper }}
            anchor={anchor}
            open={state[position]}
            onClose={toggleDrawer(position, false)}
            onOpen={toggleDrawer(position, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
})

export default CoimexDrawer
